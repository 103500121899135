import * as React from 'react';
import LayoutArraiolos from '../components/LayoutArraiolos';
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import "../components/all.sass";
import PageTitles from '../components/helpers/pageTitles';
import { SEO } from "../components/Seo";

const ProjectsPage = ({ data, location }) => {
  const projects = data.allMarkdownRemark.edges;

  return (
    <LayoutArraiolos pageTitle={PageTitles.PROJECTS} sideColumnsSize="is-one-fifth" location={location}>
      <div className="column is-flex content is-vcentered is-fullheight garam layout-margin">
        <div className='columns is-multiline'>
          {projects?.map((project) => {
            const displayPhoto = getImage(project.node.frontmatter.coverPhoto);
            const displayName = project.node.frontmatter.name;
            const linkTo = project.node.fields.slug;

            return (
              <div className='column is-one-third is-full-mobile project-overview fade-in' key={displayName}>
                <Link to={linkTo}>
                  <GatsbyImage image={displayPhoto} objectFit={"cover"} className="project-featured-photo" alt={`${displayName} | featured photograph`} loading="eager" />
                  <span>{displayName}</span>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </LayoutArraiolos>
  );
};

export default ProjectsPage;

export const Head = () => (
  <SEO title={PageTitles.PROJECTS} />
);

export const ProductsPageQuery = graphql`
query ProductsPage{
  allMarkdownRemark(
    filter: {frontmatter: {templateKey: {eq: "project"}}}
    sort: {order: DESC, fields: frontmatter___order}
    ) {
      edges {
      node {
        fields {
          slug
        }
        frontmatter {
          name
          coverPhoto {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
      }
    }
   }
 `